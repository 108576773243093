
import { reactive } from 'vue'
import _list from '@/assets/js/list'

export default {
	components: {},
	setup(props, context) {
		return {
			options: reactive({}),
			setting: reactive({
				moduleName: '核销记录',
				searchApi: '/Order/Order/finishList',
				moduleType: 'full',
				tableFirstType: 'selection',
			}),
			
			list: reactive({
				data: [ ]
			}),
		}
	},
	mounted() {
		this._load(this, _list)
		this._listSearch()
	},
	methods: {
		
	}
}
